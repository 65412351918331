<template>
  <div class="pc-description">
    <div class="phone-to-pc">
      <p>为了向您提供最佳的产品使用体验</p>
      <p>请前往电脑端进行访问</p>
      <a :href="guideUrl">{{guideUrl}}</a><br/>
      <p @click="handleClick" style="color:#0084FF;margin-top:5px;">继续访问</p>
    </div>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex';

  export default {
    name: '',
    data() {
      return {}
    },
    computed: {
      guideUrl() {
        return window._CONFIG['TO_GUIDE_URL']
      }
    },
    components: {},
    methods: {
      ...mapMutations('pcDescription', ['mtt_flag']),
      handleClick() {
        this.mtt_flag(true)
        this.$router.push('/home')
      }
    }
  }
</script>

<style scoped lang="scss">
  .pc-description {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/home/mobile-bg.jpg") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .phone-to-pc {
      width: 90%;
      height: 35vh;
      background: url("../../assets/img/pc-description/phone-to-pc.png") no-repeat center;
      background-size: cover;
      font-size: 16px;
      font-weight: 500;
      color: #323232;
      text-align: center;
      padding-top: 20vh;
      p {
        margin: 0;
      }
    }
  }
</style>