<template>
  <div class="mobile-bridge">
    <div class="sign-success">
      <img src="../../assets/img/bridge/sign-success.png"/>
      <div class="line"></div>
      <div class="sign-bottom">
        <img src="../../assets/img/bridge/notice.png"/>
        <span>提示：请用手机号进行登录, 初始密码为身份证后六位</span>
      </div>

    </div>
    <div class="personal-info" @click="handlePersonalInfo">
      <div class="title">个人信息</div>
      <img src="../../assets/img/bridge/mobile-personal-info.png"/>
    </div>
    <div class="study-platform" @click="handleStudyPlatform" v-if="openToPlatform">
      <div class="title">学习平台</div>
      <div class="platform-content">
        <img src="../../assets/img/bridge/mobile-to-platform.png"/>
        <div>
          <p>巧匠赛道</p>
          <p>第十四届中兴捧月“星匠师”巧匠精英挑战赛——是基于新一代信息通信...</p>
        </div>
      </div>
    </div>
    <el-tooltip class="item" effect="dark" :content="tooltipContent" placement="top" v-else>
      <div class="study-platform" style="filter: grayscale(100%);">
        <div class="title">学习平台</div>
        <div class="platform-content">
          <img src="../../assets/img/bridge/mobile-to-platform.png"/>
          <div>
            <p>巧匠赛道</p>
            <p>第十四届中兴捧月“星匠师”巧匠精英挑战赛——是基于新一代信息通信...</p>
          </div>
        </div>
      </div>
    </el-tooltip>


  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {
        startTime: window._CONFIG['TO_STUDY_START_TIME']
      }
    },
    components: {},
    computed: {
      tooltipContent() {
        return `即将开放此功能，敬请期待`
      },
      // 是否开启跳转学习平台判断
      openToPlatform() {
        const {startTime} = this
        let timeStart = new Date(startTime).getTime()
        let currentTime = new Date().getTime()
        return currentTime > timeStart
      }
    },
    methods: {
      handlePersonalInfo() {
        this.$router.replace({name: 'mobile-personal-center'});
      },
      handleStudyPlatform() {
        // let url = `${window.location.origin}/training`;
        let url = window._CONFIG['TO_STUDY_URL']
        window.open(url, '_blank')
      }
    }
  }
</script>

<style scoped lang="scss">
  .mobile-bridge {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/home/mobile-bg.jpg") no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sign-success {
      top: 15px;
      width: 93%;
      height: 118px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      padding: 20px 0;
      font-size: 14px;
      .line {
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 52%, rgba(255, 255, 255, 0) 98%);
        margin: 10px auto;
      }
      .sign-bottom {
        img{
          width:12px;
        }
        span {
          margin-left: 5px;
        }
      }
    }

    .title {
      position: relative;
      font-size: 14px;
      color: #104175;
      margin-bottom: 10px;
      &::before {
        position: absolute;
        top: 4px;
        left: -10px;
        content: '';
        width: 2px;
        height: 12px;
        background: #104175;
      }
    }
    .personal-info {
      width: 93%;
      margin-top: 14vh;
      /*height: 22vh;*/
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
      padding: 20px 40px;

      img {
        display: block;
        margin: 10px auto;
        width: 68%;
      }
    }
    .study-platform {
      width: 93%;
      /*height: 22vh;*/
      margin-top: 4vh;
      border-radius: 10px;
      opacity: 1;
      background: linear-gradient(270deg, rgba(0, 102, 255, 0.5) 0%, rgba(210, 245, 255, 0.5) 100%), rgba(255, 255, 255, 0.8);
      box-sizing: border-box;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 30px;
      .platform-content {
        display: flex;
        justify-content: space-between;
        img {
          margin-right: 15px;
          width: 50%;
        }
        p {
          margin: 0;
          color: #0B257C;
          &:first-child {
            font-size: 18px;
            font-weight: 500;
          }
          &:last-child {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

    }

  }
</style>